import {gsap} from "gsap/dist/gsap";

const blockCollapse = () => {
    const targets = document.querySelectorAll('[data-collapse="true"]');

    targets.forEach((item, index) => {
        const button = item.querySelector('[data-collapse="button"]');
        const content = item.querySelector('[data-collapse="content"]');
        const state = 'data-collapse-state';

        const blockContentHide = () => {
            item.setAttribute(state, 'hide');
            gsap.to(content, {
                duration: 0.3,
                height: 0,
                ease: "power1.out"
            });
        }

        const blockContentShow = () => {
            item.setAttribute(state, 'show');
            gsap.to(content, {
                duration: 0.3,
                height: 'auto',
                ease: "power1.in"
            });
        }

        const blockContentAction = () => {
            if (window.matchMedia('(max-width: 767px)').matches) {
                blockContentHide();

                button.addEventListener("click", () => {
                    if (item.getAttribute(state) === 'show') {
                        blockContentHide();
                    } else {
                        blockContentShow();
                    }
                });
            } else {
                blockContentShow();
            }
        }

        blockContentAction();
        window.addEventListener("resize", blockContentAction);
    });
}

export {blockCollapse};

const emergence = () => {

    // Sélection de tous les éléments .wrapper
    const wrappers = document.querySelectorAll('.wrapper');

    // Options pour l'observateur d'intersection
    const options = {
        root: null, // Utilise le viewport comme zone d'observation
        rootMargin: '0px', // Marge autour du viewport
        threshold: 0.5 // Détecte lorsque 50% ou plus de l'élément est visible
    };

    // Fonction de rappel à exécuter lorsque l'intersection change
    const handleIntersection = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // Si l'élément est visible dans le viewport
                entry.target.setAttribute('data-visible', 'true');
                gsap.to(entry.target.querySelector('.container'), {
                    y: "0",
                    opacity: 1,
                    duration: 0.6,
                    ease: "power2.out"
                });

            } else {
                // Si l'élément n'est pas visible dans le viewport
                entry.target.setAttribute('data-visible', 'false');
                gsap.to(entry.target.querySelector('.container'), {
                    y: "-50",
                    opacity: 0,
                    duration: 0.6,
                    ease: "power2.in"
                });
            }
        });
    };

    // Pour chaque élément .wrapper, créer un observateur d'intersection
    wrappers.forEach(wrapper => {

        if (wrapper.getAttribute('data-visible') === 'false') {
            // Création de l'observateur d'intersection
            const observer = new IntersectionObserver(handleIntersection, options);

            // Commencer à observer l'élément cible
            observer.observe(wrapper);
        }
    });
}

export {emergence};
