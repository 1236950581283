// IMPORT
import * as slider from './components/slider';
import {windowHeight, obfuscation, isScrolled} from './utils';
import {emergence, blockCollapse} from './components/blocks';
import {mainMenu, burgerMenu} from './components/menus';
import {niceChosen} from './components/select';

blockCollapse();
burgerMenu();
niceChosen();
// emergence();

window.addEventListener('load', (event) => {
    slider.init();
    mainMenu();
});
