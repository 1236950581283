import {gsap} from "gsap/dist/gsap";

// IMPORT SWIPER
import Swiper from 'swiper';
import {Pagination, EffectFade} from 'swiper/modules';

export function init() {

    if (document.querySelector('.tabs')) {
        let homepageTabs = document.querySelector('.homepage-tabs');
        if (homepageTabs) {
            let pagination = homepageTabs.querySelector('.swiper-pagination');

            const updateTransitionPill = () => {
                let offsetLeft = homepageTabsCarousel.pagination.bullets[homepageTabsCarousel.activeIndex].offsetLeft;
                let offsetWidth = homepageTabsCarousel.pagination.bullets[homepageTabsCarousel.activeIndex].offsetWidth;
                gsap.to(pagination, {
                    duration: 0.4,
                    ease: "power2.out",
                    '--offset-left': `${offsetLeft}px`,
                    '--offset-width': `${offsetWidth}px`,
                });
            }

            const homepageTabsConfig = {
                init: false,
                modules: [Pagination, EffectFade],
                effect: "fade",
                speed: 1000,
                fadeEffect: {crossFade: true},
                autoHeight: true,
                allowTouchMove: false,
                pagination: {
                    el: ".tabs-pagination",
                    clickable: true,
                    renderBullet: function (index, className) {
                        const bullets = Array.from(pagination.querySelectorAll('div'));
                        return '<div class="' + className + '">' + (bullets[index].innerHTML) + '</div>';
                    },
                },
                on: {
                    init: () => {updateTransitionPill()},
                    slideChange: () => {
                        updateTransitionPill()
                        setTimeout(() => {
                            homepageTabs.scrollIntoView({
                                behavior: "smooth",
                                block: "start",
                                inline: "nearest"
                            });
                        }, 500)
                    },
                },
            };

            const homepageTabsCarousel = new Swiper(homepageTabs, homepageTabsConfig);
            homepageTabsCarousel.init();
        }
    }
}
