import {isTouch} from '../utils';
import NiceSelect from "nice-select2/dist/js/nice-select2";

// SEARCH SELECT
let niceChosenWrap = document.querySelector('.select');
const niceChosen = () => {
    if (niceChosenWrap !== null) {
        if (!isTouch()) {
            const selectList = document.querySelectorAll('.select');
            selectList.forEach((item, index) => {
                item.classList.add("nice-select-init");
                const select2 = new NiceSelect(item.querySelector('select'));
            });
        }
    }
}

export {niceChosen};
