import {gsap} from "gsap/dist/gsap";

const burgerMenu = () => {
    const burgerMenuWrap = document.querySelector('.burger-menu');

    const burgerMenuHide = () => {
        document.body.classList.remove('has-burger--open');
        burgerMenuWrap.classList.remove('burger-menu--open');
        gsap.to(burgerMenuWrap.querySelector('.menu-content'), {
            x: "-390",
            duration: 0.3,
            ease: "power2.out"
        });
    }

    const burgerMenuShow = () => {
        document.body.classList.add('has-burger--open');
        burgerMenuWrap.classList.add('burger-menu--open');
        gsap.to(burgerMenuWrap.querySelector('.menu-content'), {
            x: 0,
            duration: 0.3,
            ease: "power2.in"
        });
    }

    const burgerMenuAction = () => {
        if (burgerMenuWrap !== null) {
            document.querySelector('.menu-button').addEventListener('click', () => {
                if (document.body.classList.contains('has-burger--open')) {
                    burgerMenuHide();
                } else {
                    burgerMenuShow();
                }
            });
            document.querySelector('.menu-close').addEventListener("click", burgerMenuHide);
        }
    }

    burgerMenuAction();
}

export {burgerMenu};

const mainMenu = () => {
    const menu = document.querySelector('.site-header .container > .main-menu');

    if (menu !== null) {
        const line = menu.querySelector('.line');
        const links = menu.querySelectorAll('li');
        const options = {duration: 0.4, ease: "power2.out"};

        links.forEach((link) => {
            if (link.classList.contains('active')) {
                let offsetLeft = link.offsetLeft;
                let offsetWidth = link.offsetWidth;

                line.style.setProperty('--offset-left', offsetLeft + 'px');
                line.style.setProperty('--offset-width', offsetWidth + 'px');

                menu.addEventListener('mouseleave', () => {
                    gsap.to(line, {
                        ...options,
                        '--offset-left': `${offsetLeft}px`,
                        '--offset-width': `${offsetWidth}px`,
                    });
                });
            }

            link.addEventListener('mouseenter', () => {
                gsap.to(line, {
                    ...options,
                    '--offset-left': `${link.offsetLeft}px`,
                    '--offset-width': `${link.offsetWidth}px`,
                });
            });
        });
    }
}

export {mainMenu};
